import { GATSBY_ENV } from "gatsby-env-variables"
import { capitalize } from "../../../services/general"
import { sendToZendesk } from "../../../services/zendeskService"

// import { sendMessage } from "../../../services/telerivet"
import { parseZendeskField } from "./zendeskField"
import { handleError } from "../../../services/handleError"
import { GATSBY_DOCTOR_ENROLLMENT_FIELD } from "gatsby-env-variables"
import { doctorEnrollmentTicketTemplate } from "../utils/enrollmentTicketTemplate"
// import {
//   doctorMessageTemplate,
//   patientMessageTemplate,
// } from "../utils/enrollmentSMSTemplate"

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, documents, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"

  let tags = [type, "bm_talktodoc"]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type.replace("_", " ")
  )} Form of ${enrollmentData?.firstName} ${enrollmentData?.lastName}`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.emailAddress,
    },
    custom_fields: [
      {
        id: GATSBY_DOCTOR_ENROLLMENT_FIELD,
        value: JSON.stringify({ formData: parseZendeskField(enrollmentData) }),
      },
    ],
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleUserEnroll = async ({
  type,
  enrollmentData,
  documents,
  callback,
  errorCallback,
}) => {
  try {
    await sendEnrollmentFormToZendesk({
      enrollmentData,
      documents,
      ticketTemplate:
        type === "doctor_enrollment" ? doctorEnrollmentTicketTemplate : ``,
      type: type,
    })

    // await sendMessage({
    //   message:
    //     type === "doctor" ? doctorMessageTemplate() : patientMessageTemplate(),
    //   recipient: enrollmentData?.mobileNumber,
    // })

    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
