export const parseZendeskField = enrollmentData => {
  if (enrollmentData?.specialization) {
    enrollmentData.parsedSpecialization =
      enrollmentData.specialization.value !== "Other (please specify)"
        ? enrollmentData.specialization.value
        : enrollmentData.otherSpecialization
  }

  if (enrollmentData?.specialization) {
    enrollmentData.parsedHospitalClinic =
      enrollmentData.hospitalClinic.value !== "Other"
        ? enrollmentData.hospitalClinic.value
        : enrollmentData.otherHospital
  }

  if (enrollmentData?.wouldLikeToReceiveUpdates) {
    enrollmentData.parsedUpdates =
      enrollmentData.wouldLikeToReceiveUpdates.length > 0 ? "Yes" : "No"
    delete enrollmentData.wouldLikeToReceiveUpdates
  }

  return enrollmentData
}
