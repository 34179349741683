export const doctorEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    prcNumber,
    specialization,
    hospitalClinic,
    otherHospital,
    otherSpecialization,
    hospitalDepartment,
    otherDepartment,
    parsedUpdates,
  } = enrollmentData

  let doctorSpecialization =
    specialization.value === "Other (please specify)"
      ? otherSpecialization
      : specialization.value

  let hospitalOrClinic =
    hospitalClinic.value === "Other" ? otherHospital : hospitalClinic.value

  let parsedDepartment =
    hospitalDepartment.value === "Other (please specify)"
      ? otherDepartment
      : hospitalDepartment.value

  return `Personal Details
First Name: ${firstName}
Last Name: ${lastName}
Middle Initial: ${middleInitial || "N/A"}
PRC License Number: ${prcNumber}
Specialization: ${doctorSpecialization}
Clinic or Hospital: ${hospitalOrClinic}
Department: ${parsedDepartment}
----
Mobile Number: ${mobileNumber}
Email: ${emailAddress}
I would like to receive an update whenever my patients successfully apply for a diagnostic subsidy: ${parsedUpdates}`
}
